import { createSlice } from '@reduxjs/toolkit';
import { IStatus } from '../../../@types/status';
import { getUser, getUsers } from './actions';
var initialState = {
    users: { docs: [], meta: {} },
    user: {},
    status: IStatus.IDLE,
};
var slice = createSlice({
    name: 'users',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder
            .addCase(getUsers.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getUsers.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.users = action.payload;
        })
            .addCase(getUsers.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(getUser.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getUser.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.user = action.payload;
        })
            .addCase(getUser.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
    },
});
export default slice.reducer;
