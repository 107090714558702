import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch, useSelector as useAppSelector, } from 'react-redux';
import rootReducer from './rootReducer';
var store = configureStore({
    reducer: rootReducer,
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: true,
        });
    },
});
var resetState = function () { return ({
    type: 'RESET_STATE',
}); };
// Add the following function to refresh the state
var refreshState = function () {
    store.dispatch(resetState());
};
var dispatch = store.dispatch;
var useSelector = useAppSelector;
var useDispatch = function () { return useAppDispatch(); };
export { dispatch, refreshState, store, useDispatch, useSelector };
